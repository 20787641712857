import { fireFilterSubmit } from "./FilterFormActions";
import { forEachElement } from "../util/Utils";
import { eventQBus } from "../types/EventQBus";
import type { FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import type { TrackingLabels } from "../tracking/TrackingLabels";

export interface ExtendedChangeEvent extends Event {
  triggerSubmit?: boolean;
  filterMethod?: TrackingLabels["san_FilterMethod"];
}

const CLASS_SUBMIT_ON_CHANGE = "js_submitOnChange";
const SELECTOR_SUBMIT_ON_CHANGE = `.${CLASS_SUBMIT_ON_CHANGE}`;

export type SubmitMethod = (target: HTMLFormElement | HTMLInputElement | HTMLSelectElement) => void;
export type FormMethod = (target: HTMLFormElement) => void;

/**
 *
 */
export class SubmitOnChangeListener implements EventListenerObject {
  /**
 *
 *
 *
 */
  private constructor(
    private readonly triggerMethod: SubmitMethod = fireFilterSubmit,
    private readonly excludeSelector: string = "",
    private readonly triggeredTypes: string[] = ["checkbox", "radio", "option"],
  ) {}

  /*                  */

  static submitOnChange(triggerMethod?: SubmitMethod, excludeSelector?: string, triggeredTypes?: string[]) {
    return new SubmitOnChangeListener(triggerMethod, excludeSelector, triggeredTypes);
  }

  private static fromElement({ submitOnChangeTypes }: DOMStringMap) {
    return new SubmitOnChangeListener(undefined, undefined, submitOnChangeTypes?.split(","));
  }

  static onForForm(
    elem: HTMLElement,
    form: string,
    beforeSubmit: FormMethod | undefined = undefined,
    listener: SubmitOnChangeListener = SubmitOnChangeListener.fromElement(elem.dataset),
  ) {
    SubmitOnChangeListener.on(
      elem,
      SubmitOnChangeListener.submitOnChange((target: HTMLFormElement | HTMLInputElement | HTMLSelectElement) => {
        if (target instanceof HTMLInputElement) {
          const targetForm = document.querySelector(`form[id=${form}]`);
          if (targetForm instanceof HTMLFormElement) {
            if (beforeSubmit) {
              beforeSubmit(targetForm);
            }
            listener.triggerMethod(targetForm);
            return;
          }
        }
        listener.triggerMethod(target);
      }),
    );
  }

  static on(elem: HTMLElement, listener = SubmitOnChangeListener.fromElement(elem.dataset)) {
    /*               */
    /*                                                                                                  */
    /*                                                                                             */
    /*                                                                                              */
    /*                                                                                               */
    elem.addEventListener("change", listener, {
      capture: true,
      passive: true,
    });

    /*                                        */
    elem.classList.remove(CLASS_SUBMIT_ON_CHANGE);
  }

  /*               */

  static register() {
    eventQBus.on("heureka.filters.loaded", SubmitOnChangeListener.initAll);
  }

  static initAll(event?: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    forEachElement(SELECTOR_SUBMIT_ON_CHANGE, (elem) => SubmitOnChangeListener.on(elem), rootElement);
  }

  /*                 */

  handleEvent(event: ExtendedChangeEvent) {
    const target = event.target;

    /*                                                                                                           */
    if (this.excludeSelector && target instanceof HTMLElement && target.closest(this.excludeSelector)) {
      return;
    }

    /*                                                 */
    if (target instanceof HTMLInputElement) {
      /*                                                 */
      if (this.triggeredTypes.indexOf(target.type) >= 0 && !target.dataset.noSubmitOnChange) {
        this.trigger(target);
        event.triggerSubmit = true;
      }
    } else if (target instanceof HTMLFormElement || target instanceof HTMLSelectElement) {
      /*                           */
      this.trigger(target);
      event.triggerSubmit = true;
    }
  }

  private trigger(target: HTMLInputElement | HTMLFormElement | HTMLSelectElement) {
    /*                                                             */
    /*                                                                   */
    /*                                 */
    window.setTimeout(this.triggerMethod, 0, target);
  }
}
