import { getElementById } from "../util/Utils";
import { any } from "../util/Reductions";

const ToggleNames = [
  "DUMMY",
  "HEUREKA_663_CLUSTER_REDUZIERUNG_FIRST_LEVEL",
  "HEUREKA_663_CLUSTER_REDUZIERUNG_FIRST_LEVEL_EXPERIMENT",
  "HEUREKA_663_CLUSTER_REDUZIERUNG_FIRST_LEVEL_TRACKING",
  "HEUREKA_1153_CHIPS_WITH_PRICE_RANGES",
  "HEUREKA_1153_CHIPS_WITH_PRICE_RANGES_E3131",
  "HEUREKA_1227_IGNORE_MERGEONPI_ATTR_ON_LOAD_FAILURE",
  "HEUREKA_1283_RANGE_CHIPS_FOR_SIZES",
  "HEUREKA_1283_RANGE_CHIPS_FOR_SIZES_E3001",
  "HEUREKA_1363_RETAILER_OTTO_TOGGLE",
  "HEUREKA_1363_RETAILER_OTTO_TOGGLE_E3245",
  "HEUREKA_1338_CRISPY_FILTER_LISTS",
  "HEUREKA_1338_CRISPY_FILTER_LISTS_E3213",
  "HEUREKA_1367_REFACTOR_FACET_VALUE_TAGS",
  "HEUREKA_1523_HIDE_BANNER_IF_OTTO_IS_ABSENT",
  "HEUREKA_1524_UNLOCK_FILTERSECTION_ON_ZERO_HITS_PAGE",
] as const;

export type ToggleName = (typeof ToggleNames)[number];
export type ToggleStates = Record<ToggleName, boolean>;

let toggles: ToggleStates;

function asBoolean(value?: string): boolean {
  return "true" === value;
}

export function initToggles() {
  const data = getElementById("leafcutter_toggles")?.dataset;
  const state = data
    ? (toggleName: ToggleName) => asBoolean(data[toggleName] || data[toggleName.toLowerCase()])
    : () => false;
  toggles = ToggleNames.reduce((htmlToggles, toggleName) => {
    htmlToggles[toggleName] = state(toggleName);
    return htmlToggles;
  }, {} as Partial<ToggleStates>) as ToggleStates;
}

/**
 *
 */
export function updateToggle(toggle: ToggleName, state: boolean) {
  toggles[toggle] = state;
}

export function isInactive(toggle: ToggleName) {
  return !isActive(toggle);
}

export function isActive(toggle: ToggleName) {
  return toggles[toggle];
}

export function isAnyActive(...toggles: ToggleName[]) {
  return toggles.map(isActive).reduce(any, false);
}
