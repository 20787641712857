import { prepareExpander } from "../expander/Expander";
import { OverflowBox, prepareOverflowBoxContainers } from "../overflowBox/OverflowBox";
import { FilterAccordion } from "./FilterAccordion";
import { SmartSorting } from "../sorting/SmartSorting";
import { FilterTitle } from "./FilterTitle";
import { Facet } from "./Facet";
import { FacetGroupSelect } from "./FacetGroupSelect";
import { HeurekaElementFactory } from "../util/HeurekaElementFactory";
import { eventQBus } from "../types/EventQBus";
import type { DresonRuleResolvedEvent, FilterSectionLoadedEvent } from "../multifiltering/FilterTypes";
import { element, isProductList } from "../util/Utils";
import { ShadowFacet } from "./ShadowFacet";
import { FilterRelevancy } from "./FilterRelevancy";
import { getActiveVariation, isActiveVariation } from "../experiment/Experiments";
import { isActive, isAnyActive } from "../toggle/Toggle";
import { FilterSortingOld } from "./FilterSortingOld";
import { FilterlistExpander } from "../filterlistExpander/FilterlistExpander";
import { Filter } from "./Filter";

const FILTER_SECTION_ID = "find_filterSection";
const ERROR_MESSAGE_SELECTOR = ".heureka_filterSection--errorMessage";

export class FilterSection {
  static readonly factory = HeurekaElementFactory.byId(FILTER_SECTION_ID, FilterSection);

  /*               */
  constructor(readonly filterSection: HTMLElement) {}

  /*                  */
  static template(rootElement?: ParentNode | null) {
    return FilterSection.factory.pick(undefined, rootElement);
  }

  /*               */
  static register() {
    /*      */
    eventQBus.on("ftfind.dresonRule.resolve", FilterSection.lockAll);
    eventQBus.on("ftfind.dresonRule.resolved", FilterSection.unlockAllImmediately);
    eventQBus.on("ftfind.dresonRule.resolveAborted", FilterSection.unlockAll);

    /*        */
    eventQBus.on("heureka.filters.loaded", FilterSection.unlockAll);
    eventQBus.on("heureka.filters.loadAborted", FilterSection.unlockAll);
    eventQBus.on("heureka.filterSection.loadAborted", FilterSection.unlockAll);

    /*     */
    eventQBus.on("heureka.filterSection.loadAborted", FilterSection.showErrorMessage);
  }

  static initAll(event: FilterSectionLoadedEvent, rootElement?: ParentNode) {
    FilterSection.factory.forEach((filterSection) => filterSection.init(), rootElement);
  }

  static prepare(fragment: DocumentFragment, initialLoad = false) {
    prepareExpander(fragment);
    OverflowBox.prepareAll(fragment);
    prepareOverflowBoxContainers(fragment);
    if (
      isAnyActive("HEUREKA_1338_CRISPY_FILTER_LISTS_E3213", "HEUREKA_1338_CRISPY_FILTER_LISTS") &&
      isActiveVariation("e3213", "TestGroup") &&
      !isProductList()
    ) {
      FilterRelevancy.prepareAll(fragment, initialLoad);
    }
    Facet.prepareAll(fragment);
    if (getActiveVariation("e3213", "StatusQuo") === "StatusQuo") {
      /*                                                        */
      ShadowFacet.prepareAll(fragment);
    }
    FilterAccordion.prepareAll(fragment, initialLoad);
    FilterTitle.prepareAll(fragment);
    SmartSorting.prepareAll(fragment);
    FacetGroupSelect.prepareAll(fragment);
    if (
      isAnyActive("HEUREKA_1338_CRISPY_FILTER_LISTS_E3213", "HEUREKA_1338_CRISPY_FILTER_LISTS") &&
      isActiveVariation("e3213", "TestGroup") &&
      !isProductList()
    ) {
      Filter.prepareAll(fragment, initialLoad);
      FilterlistExpander.prepareAll(fragment);
    } else {
      FilterSortingOld.prepareAll(fragment);
    }
  }

  static showErrorMessage() {
    FilterSection.factory.forEach((filterSection) => filterSection.showError());
  }

  static lockAll() {
    FilterSection.factory.forEach((filterSection) => filterSection.lock());
  }

  static unlockAll() {
    FilterSection.factory.forEach((filterSection) => filterSection.unlock());
  }

  static unlockAllImmediately(event: DresonRuleResolvedEvent) {
    /*                                                          */
    if (isActive("HEUREKA_1524_UNLOCK_FILTERSECTION_ON_ZERO_HITS_PAGE")) {
      if (!event.mergeOnPi || event.count == 0) {
        /*                 */
        FilterSection.unlockAll();
      }
    } else {
      if (!event.mergeOnPi) {
        /*                 */
        FilterSection.unlockAll();
      }
    }
  }

  protected init() {
    return this;
  }

  public lock() {
    this.filterSection.classList.add("filterWrapper--loading");
    return this;
  }

  public unlock() {
    this.filterSection.classList.remove("filterWrapper--loading");
    return this;
  }

  public get errorMessage() {
    return element(ERROR_MESSAGE_SELECTOR, this.filterSection);
  }

  public showError() {
    const { errorMessage } = this;
    if (errorMessage) {
      errorMessage.hidden = false;
    }
  }
}
