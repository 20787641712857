const FILTER_SHEET_BUTTON_SELECTOR = "#find_filterSheetButton";
const QUICK_FILTER_BUTTON_SELECTOR = "#find_quickFilterButton";
const FILTER_SHEET_TEMPLATE_ID = "heureka_filterSheetTemplate";
const FILTER_TITLES_ID = "heureka_filterTitles";
const FIND_FILTERLIST_MOBILE = "find_filterList_mobile";
const FILTER_SHEET_FOOTER_TEMPLATE_ID = "heureka_filterSheetFooterTemplate";
const LOCAL_NAVIGATION_SELECTOR = ".reptile_sidebar__localNavigation";
const LOCAL_NAVIGATION_TITLE_SELECTOR = "[data-overlay-entry-title]";

export function filterSheetButton() {
  return document.querySelector<HTMLElement>(FILTER_SHEET_BUTTON_SELECTOR);
}

export function quickFilterButton() {
  return document.querySelector<HTMLElement>(QUICK_FILTER_BUTTON_SELECTOR);
}

export function filterSheetTemplate() {
  return document.getElementById(FILTER_SHEET_TEMPLATE_ID);
}

export function filterTitles() {
  return document.getElementById(FILTER_TITLES_ID);
}

export function filterListMobile() {
  return document.getElementById(FIND_FILTERLIST_MOBILE);
}

export function filterTitle(filterId: string) {
  return document.querySelector<HTMLElement>(
    `.find_filterTitles__item[data-filter-id=${filterId}], .heureka_filterTitle[data-filter-id=${filterId}]`,
  );
}

export function filterSheetFooterTemplate() {
  return document.getElementById(FILTER_SHEET_FOOTER_TEMPLATE_ID);
}

export function localNavigation() {
  return document.querySelector<HTMLElement>(LOCAL_NAVIGATION_SELECTOR);
}

export function selectedNavigationTitle(localNavigation: HTMLElement | null) {
  return localNavigation?.querySelector<HTMLElement>(LOCAL_NAVIGATION_TITLE_SELECTOR);
}

export function filter(filterId: string) {
  return document.getElementById(`find_filter_${filterId}`) as HTMLFormElement;
}

export function facet(facetId: string) {
  return document.getElementById(`facet_${facetId}`) as HTMLFieldSetElement;
}

export function filterForm(filter: HTMLElement | null) {
  return filter?.querySelector<HTMLFormElement>(".find_filter") || null;
}

export function filterMessage(filter: HTMLElement | null) {
  return filter?.querySelector<HTMLElement>(".find_filterMessage") || null;
}

export function filterFooter(filter: HTMLElement | null) {
  return filter?.querySelector<HTMLElement>(".find_filter__footer") || null;
}

export function menuBarButtonForm(): HTMLFormElement | null {
  return document.querySelector<HTMLFormElement>(`.heureka_menu-bar-button`) || null;
}

export function sheetContent() {
  return document.querySelector<HTMLElement>(".pl_sheet .pl_sheet__content");
}

export function sheetHeader() {
  return document.querySelector<HTMLElement>(".pl_sheet .pl_sheet__header");
}

export function sheetMenuBar() {
  return document.querySelector<HTMLElement>(".pl_sheet .pl_sheet__menu-bar");
}

export function sheetMenuBackBackButton(): HTMLButtonElement | null {
  return document.querySelector<HTMLButtonElement>(`.heureka_filterSheet .find_filterSheet__menuBackButton`) || null;
}
