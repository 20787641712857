import StringHelper from "../util/StringHelper";
import { appendAll, clear, forEachElement, notEmpty } from "../util/Utils";
import { eventQBus } from "../types/EventQBus";
import { copyLegacyLabels, updateLegacyLabel } from "../tracking/FeatureTracking";
import { Facet } from "./Facet";
import { createFacetValuesStore } from "./FacetValuesStore";
import { get, type Readable } from "svelte/store";
import type { FacetValue } from "./FacetValue";
import type { PopularFacetValue } from "./PopularFacetValue";
import { createPopularValuesStore } from "./PopularFacetValuesStore";

export const SEARCH_TERM_NON_MATCHING_FACET_VALUE_CLASS = "find_facetValue--searchTermNonMatching";
const FACET_SEARCHING_CLASS = "find_facet--searching";

export interface SearchBoxUpdate {
  text: string;
  id?: string;
}

export type SearchBoxTopics = {
  "heureka.searchBox.update": [SearchBoxUpdate];
};

/**
 *
 *
 *
 */
export function createHighlightedString(originalText: string, highlightRegex: RegExp) {
  const matched = originalText.match(highlightRegex);
  if (!matched) {
    return originalText;
  }
  const highlightedIndex = matched.index;
  if (typeof highlightedIndex === "undefined") {
    return originalText;
  }
  const matchedElement = matched[0];
  const preMatch = originalText.substring(0, highlightedIndex);
  const postMatch = originalText.substring(highlightedIndex + matchedElement.length);
  return `${preMatch}<b>${matchedElement}</b>${postMatch}`;
}

function createRegularExpressionFrom(text: string) {
  const escapedSearchTerm = StringHelper.regexEscapeLiteral(text);
  let regexStr = "";
  for (let i = 0; i < escapedSearchTerm.length; i++) {
    const filterChar = escapedSearchTerm.charAt(i);
    regexStr += StringHelper.replaceVowelAccentsWithRegExp(filterChar);
  }
  return new RegExp(regexStr, "i");
}

function updateMatchingFacetValues(facetValuesStore: Readable<FacetValue[]>, regExp: RegExp, facet: Facet) {
  const filteredValues = get(facetValuesStore)
    .filter((value: FacetValue) => regExp.exec(value.title))
    .map((value) => {
      value.spanInnerHTML = createHighlightedString(value.title, regExp);
      return value.liElem;
    })
    .filter(notEmpty);
  const facetContent = facet?.facetContent;
  if (facetContent && filteredValues) {
    clear(facetContent);
    appendAll(filteredValues, facetContent);
  }
  return filteredValues;
}

function updateMatchingPopularValues(popularValuesStore: Readable<PopularFacetValue[]>, regExp: RegExp, facet: Facet) {
  const popularFilteredValues = get(popularValuesStore)
    .filter((value: PopularFacetValue) => regExp.exec(value.title))
    .map((value) => {
      value.spanInnerHTML = createHighlightedString(value.title, regExp);
      return value.popularFacetValue;
    })
    .filter(notEmpty);
  const popularFacetValuesContent = facet.popularFacetValuesWrapper?.content;
  if (popularFacetValuesContent && popularFilteredValues) {
    clear(popularFacetValuesContent);
    appendAll(popularFilteredValues, popularFacetValuesContent);
  }
  return popularFilteredValues;
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
function updateVisibleItems(
  facet: Facet,
  msgField: Element | null,
  facetValuesStore: Readable<FacetValue[]>,
  popularValuesStore: Readable<PopularFacetValue[]>,
  text: string,
) {
  const regExp = createRegularExpressionFrom(text);
  facet.facet.classList.toggle(FACET_SEARCHING_CLASS, text !== "");
  const filteredValues = updateMatchingFacetValues(facetValuesStore, regExp, facet);
  const popularFilteredValues = updateMatchingPopularValues(popularValuesStore, regExp, facet);

  const visibleItemsCount = filteredValues.length + popularFilteredValues.length;
  msgField?.classList.toggle("pl_input--invalid", !visibleItemsCount);
}

/**
 *
 *
 *
 *
 */
function getNormalizedText(text: string): string {
  return text.replace(new RegExp("/[^a-zA-Z0-9\xC0-\xFF]/i", "g"), "");
}

/**
 *
 *
 *
 *
 *
 *
 *
 */
function inputAction(
  facet: Facet,
  msgField: Element | null,
  facetValuesStore: Readable<FacetValue[]>,
  popularValuesStore: Readable<PopularFacetValue[]>,
  event: Event,
): void {
  const target = event.target as HTMLInputElement;
  const text = target?.value || "";
  const normalizedFacetInput = getNormalizedText(text).trim();
  updateVisibleItems(facet, msgField, facetValuesStore, popularValuesStore, normalizedFacetInput);
}

/**
 *
 *
 *
 *
 *
 */
function trackUsage(event: Event): void {
  const elem = event.target as HTMLElement;
  const { tsLabels } = elem?.dataset || {};
  if (tsLabels) {
    eventQBus.emit("tracking.bct.submitEvent", JSON.parse(tsLabels));
  }
  elem?.removeEventListener("input", trackUsage);
}

/**
 *
 *
 *
 */
function registerUsageTracker(event: Event | { target: EventTarget }) {
  event.target?.addEventListener("input", trackUsage);
}

function updateFacetSearchTermLabel(this: HTMLFormElement, target: HTMLInputElement) {
  const text = target.value || "none";
  updateLegacyLabel(this, "san_FacetSearchTerm", text);
  eventQBus.emit("heureka.searchBox.update", { text, id: target.id });
}

export function resetSearchField(facet: Facet) {
  const searchBox = document.getElementById("searchBox_facet_" + facet.id);
  if (searchBox instanceof HTMLInputElement) {
    searchBox.value = "";
    searchBox.dispatchEvent(new Event("input", { bubbles: true }));
  }
}

/**
 *
 *
 *
 */
function create(elem: HTMLElement) {
  const inputSelector = elem.dataset.searchableFacetInput || "input[type='text']";
  const msgSelector = elem.dataset.searchableFacetMessage || "none";

  const searchBox = elem.querySelector<HTMLInputElement>(inputSelector);
  const msgField = elem.querySelector(msgSelector);

  if (searchBox) {
    const facet = Facet.factory.declare(elem);
    if (facet) {
      const facetValuesStore: Readable<FacetValue[]> = createFacetValuesStore(facet.unSelectedFacetValues);
      const popularValuesStore: Readable<PopularFacetValue[]> = createPopularValuesStore(
        facet.popularFacetValuesWrapper?.facetValues,
      );

      searchBox.addEventListener(
        "input",
        inputAction.bind(searchBox, facet, msgField, facetValuesStore, popularValuesStore),
        {
          passive: true,
        },
      );
      searchBox.form?.addEventListener(
        "reset",
        () => updateVisibleItems(facet, msgField, facetValuesStore, popularValuesStore, ""),
        {
          passive: true,
        },
      );
    }

    if (searchBox.form) {
      copyLegacyLabels(searchBox, searchBox.form);
      const listener = updateFacetSearchTermLabel.bind(searchBox.form, searchBox);
      searchBox.addEventListener("input", listener, { passive: true });
      searchBox.form.addEventListener("reset", listener, {
        passive: true,
      });
    } else {
      registerUsageTracker({ target: searchBox });
      searchBox.addEventListener("change", registerUsageTracker);
    }
  }
}

/**
 *
 */
function onFilterSectionLoaded() {
  forEachElement(".js_searchableFacet", create);
}

/**
 *
 */
export default function registerSearchableFacetHandlers() {
  eventQBus.on("heureka.filters.loaded", onFilterSectionLoaded);
}
