import { eventQBus } from "../types/EventQBus";
import { filterSheetButton, menuBarButtonForm, quickFilterButton } from "../filtersheet/Selectors";
import { forEachElement } from "../util/Utils";
import type { TrackingLabels } from "./TrackingLabels";
import { mergeToLegacyLabel } from "./FeatureTracking";
import { Filter } from "../filter/Filter";

const FACET_VALUE_TAG_FORM_SELECTOR = ".find_facetValueTags form";

export function updateWhatIfTracking(sanFilterWhatIf: TrackingLabels["san_FilterWhatIf"]) {
  eventQBus.emit("tracking.bct.addToPageImpression", {
    san_FilterWhatIf: sanFilterWhatIf,
  });
  updateSanFilterWhatIf(filterSheetButton(), sanFilterWhatIf);
  updateSanFilterWhatIf(quickFilterButton(), sanFilterWhatIf);
  updateSanFilterWhatIf(menuBarButtonForm(), sanFilterWhatIf);
  forEachElement(FACET_VALUE_TAG_FORM_SELECTOR, (elem) => updateSanFilterWhatIf(elem, sanFilterWhatIf));
  Filter.factory.forEach((filter) => (filter.sanFilterWhatIf = sanFilterWhatIf));
}

export function updateSanFilterWhatIf(
  element: HTMLElement | null,
  san_FilterWhatIf: TrackingLabels["san_FilterWhatIf"],
) {
  if (element) {
    mergeToLegacyLabel(element, "san_FilterWhatIf", san_FilterWhatIf);
  }
}
