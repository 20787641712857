import { eventQBus } from "../types/EventQBus";
import type { FilterSectionLoadEvent } from "./FilterTypes";
import type { FacetActivity } from "./FacetActivity";
import { Facet } from "../filter/Facet";
import { FilterAccordions } from "../filter/FilterAccordions";
import { FacetGroupSelect } from "../filter/FacetGroupSelect";
import { ShadowFacet } from "../filter/ShadowFacet";

type FacetVisibilities = Record<string, boolean>;

const VISIBILITIES_URI = "/leafcutter/filtervisibilities";
const INITIAL_FACET_VISIBILITIES_SELECTOR = "#heureka_initial_facetVisibilities";
const FACET_VISIBILITIES_ID = "heureka_facetVisibilities";
const FACET_VISIBILITIES_SELECTOR = "#" + FACET_VISIBILITIES_ID;

export function updateFacetVisibilities(facetVisibilities: FacetVisibilities) {
  const { facetRelevantSet, facetRelevantSetStatus } = prepareRelevantSet();

  Facet.factory.forEach((facet) => {
    const facetId = facet.name;
    if (facetId) {
      facet.updateVisibility(!facetVisibilities[facetId]);
      facetRelevantSetStatus.set(facetId, facet.activity);
    }
  });
  ShadowFacet.factory.forEach((facet) => {
    const facetId = facet.name;
    if (facetId) {
      facet.updateVisibility(!facetVisibilities[facetId]);
    }
  });

  FacetGroupSelect.ensureValidState();
  trackRelevantSetStatus(facetRelevantSet, facetRelevantSetStatus);
  FilterAccordions.template()?.updateFeaturesPositions();
}

export function updateFilterVisibilities(facetVisibilities: FacetVisibilities) {
  /*                                      */
  if (facetVisibilities) {
    updateFacetVisibilities(facetVisibilities);
  }
}

function trackRelevantSetStatus(facetRelevantSet: string[], facetRelevantSetStatus: Map<string, FacetActivity>) {
  eventQBus.emit("tracking.bct.addToPageImpression", {
    san_FacetRelevantSetStatus: facetRelevantSet.map((facetId) => facetRelevantSetStatus.get(facetId)).join("|"),
  });
}

function prepareRelevantSet() {
  const facetRelevantSet: string[] = window.o_san.facetRelevantSet || [];
  const facetRelevantSetStatus: Map<string, FacetActivity> = new Map<string, FacetActivity>();
  facetRelevantSet.forEach((facet) => facetRelevantSetStatus.set(facet, "hidden"));

  return {
    facetRelevantSet,
    facetRelevantSetStatus,
  };
}

export function onFilterVisibilitiesLoad(data: FilterSectionLoadEvent) {
  const loadFilterVisibilities = fetch(uri(data.rule)).then(extractResponseText);

  const promise = Promise.all([loadFilterVisibilities]);
  return promise
    .then((data) => {
      /*                  */
      const filterVisibilities = JSON.parse(data[0]); /*                                                                      */

      if (!filterVisibilities || Object.keys(filterVisibilities.facetVisibilities).length === 0) {
        throw Error("No filter visibilities provided!");
      }

      /*                                             */
      persistFacetVisibilities(filterVisibilities.facetVisibilities);
    })
    .catch((e) => handleError(e));
}

function extractResponseText(resp: Response): Promise<string> {
  if (resp.status !== 200) {
    throw Error(`Failed to retrieve filter visibilities: ${resp.status} ${resp.statusText}`);
  }
  return resp.text();
}

function persistFacetVisibilities(facetVisibilities: FacetVisibilities) {
  const elem = document.querySelector(FACET_VISIBILITIES_SELECTOR);
  if (elem instanceof HTMLElement && facetVisibilities) {
    elem.dataset.facetVisibilities = JSON.stringify(facetVisibilities);
  }
}

function readPersistedFacetVisibilities() {
  const elem = document.querySelector(FACET_VISIBILITIES_SELECTOR);
  if (elem instanceof HTMLElement && elem.dataset.facetVisibilities) {
    return JSON.parse(elem.dataset.facetVisibilities);
  }
}

export function updateFilterVisibilitiesFromPersisted() {
  updateFilterVisibilities(readPersistedFacetVisibilities());
  eventQBus.emit("heureka.filterVisibilities.loaded");
}

function handleError(e: Error): void {
  console.warn(`Filter visibilities reload AJAX GET against ${VISIBILITIES_URI} failed: ${e.message}`);
  eventQBus.emit("tracking.bct.addToPageImpression", {
    san_error: "filtervisibilities_notloaded",
  });
}

function uri(dataRule: string) {
  return `${VISIBILITIES_URI}?rule=${encodeURIComponent(dataRule)}`;
}

export function moveInitialFacetVisibilities() {
  const initialVisibilities = document.querySelector(INITIAL_FACET_VISIBILITIES_SELECTOR);
  if (initialVisibilities instanceof HTMLElement && !document.querySelector(FACET_VISIBILITIES_SELECTOR)) {
    const elem = document.createElement("div");
    elem.hidden = true;
    elem.id = FACET_VISIBILITIES_ID;
    elem.dataset.facetVisibilities = initialVisibilities.dataset.facetVisibilities;
    document.body.appendChild(elem);
  }
}
